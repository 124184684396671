import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { PropType } from "./types/propType";
import { Box, Grid } from "@mui/material";
import DividerImg from "./images/divider.svg";
import LocationImg from "./images/location.svg";
import TimeImg from "./images/time.svg";
import { Button } from "../../atoms/buttons";
import WebsiteArtistImage from "../../atoms/websiteArtistImage";
import ArrowImg from "./images/arrow.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./style.css";
import WebLoginDialog from "../webLoginDialog";
import WebRegisterDialog from "../webRegisterDialog";
import WebOtpDialog from "../webOtpDialog";
import WebPasswordDialog from "../webPasswordDialog";
import WebForgotPassword from "../webForgotPassword";
import WebChangePassword from "../webChangePassword";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import AlertCmp from "../../atoms/alert";
import { EventContext } from "../wizardSteps";

const EventCardWebsite = ({ data, hideViewMore, previewBanner }: PropType) => {
  const [today, setToday] = useState<any>();
  const [eventDate, setEventDate] = useState<any>();
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [resetToken, setResetToken] = useState<any>(null);
  const [activeURL, setActiveURL] = useState<any>(null);
  const { loading, error, sendRequest } = useHttp(transformData);
  const [errorText, setErrorText] = useState<any>(false);
  // const { formData, handleFormDataInput } = useContext(EventContext);
  console.log("qwerty", data.sub_type);

  function transformData(data: any) {
    if (data?.status) {
      if (data?.payments?.length > 0) {
        setActiveURL(data?.payments[0]?.payment_link);
      }
    }

    // console.log("asdf", data);
  }

  const getURL = () => {
    const reqOptions: ReqOptions = {
      url: `/payment_links`,
      method: "GET",
      params: {
        status: "true",
      },
    };

    sendRequest(reqOptions);
  };

  useEffect(() => {
    getURL();
  }, []);

  const [showErrorAlert, setShowErrorAlert] = useState({
    show: false,
    message: "",
  });
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const today = new Date();
    const date = new Date(today.toISOString().slice(0, 10));
    setToday(date);
    const eventdate = new Date(data?.date);
    setEventDate(eventdate);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const handleAlertBox = (message: any) => {
    setShowAlert({
      show: true,
      message,
    });

    setTimeout(() => {
      setShowAlert({
        show: false,
        message: "",
      });
    }, 5000);
  };

  const handleAlertErrorBox = (message: any) => {
    setShowErrorAlert({
      show: true,
      message,
    });

    setTimeout(() => {
      setShowErrorAlert({
        show: false,
        message: "",
      });
    }, 5000);
  };

  const handleCloseLoginDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenLoginDialog(false);
  };

  const handleCloseRegisterDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenRegisterDialog(false);
  };

  const handleCloseOtpDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenOtpDialog(false);
  };

  const handleClosePasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenPasswordDialog(false);
  };

  const handleCloseForgotPasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenForgotPasswordDialog(false);
  };

  const handleCloseChangePasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenChangePasswordDialog(false);
  };

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
    setOpenRegisterDialog(false);
    // setOpenOtpDialog(false);
    setToggleMobileMenu(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenRegisterDialog = () => {
    setOpenRegisterDialog(true);
    setOpenLoginDialog(false);
    // setOpenOtpDialog(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenOtpDialog = () => {
    setOpenOtpDialog(true);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenPasswordDialog = () => {
    setOpenPasswordDialog(true);
    setOpenOtpDialog(false);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenForgotPasswordDialog = () => {
    setOpenPasswordDialog(false);
    // setOpenOtpDialog(false);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenChangePasswordDialog(false);
    setOpenForgotPasswordDialog(true);
  };
  const googleLocation: any = data.location; // Replace with your desired location

  // Convert the location to a URL-friendly format
  const encodedLocation = encodeURIComponent(googleLocation);

  // Construct the Google Maps URL
  const googleMapsUrl: any = `https://www.google.com/maps/place/${encodedLocation}`;
  const currentPagePathname = window.location.pathname;

  return (
    <Box
      className={
        previewBanner
          ? "event_card_box_website event_card_box_website_preview"
          : "event_card_box_website"
      }
    >
      {errorText && (
        <AlertCmp type="error_web" position="user_side">
          we are no longer accepting bookings
        </AlertCmp>
      )}
      <Box>
        <h1
          className={
            previewBanner
              ? "event_card_heading_website event_card_heading_website_preview"
              : "event_card_heading_website"
          }
        >
          <span className="event_card_heading_span">{data.title}</span>
        </h1>
        {/* <img src={DividerImg} /> */}
      </Box>
      <Box
        className={
          previewBanner
            ? "event_card_info_box_website event_card_info_box_website_preview"
            : "event_card_info_box_website"
        }
      >
        <Box className="event_card_info_website">
          <img src={LocationImg} />
          <a
            className={
              previewBanner
                ? "event_card_info_para_website event_card_info_para_website_preview"
                : "event_card_info_para_website"
            }
            target="_blank"
            href={data?.locationLink}
          >
            {data.location}
          </a>
        </Box>
        <Box className="event_card_info_website">
          <img src={TimeImg} />
          <p
            className={
              previewBanner
                ? "event_card_info_para_website event_card_info_para_website_preview"
                : "event_card_info_para_website"
            }
          >
            {data.time}
          </p>
        </Box>
      </Box>
      <Box className="event_card_buttons_website">
        {!currentPagePathname.includes("attendee-details") &&
          eventDate?.getTime() >= today?.getTime() && (
            <>
              {data.sub_type !== "free" ? (
                <Button
                  size="s"
                  variant="outlined"
                  color="info"
                  handleClick={() => {
                    const token = localStorage.getItem("web_token");
                    const guestUser = localStorage.getItem("guest_user");
                    const currentTime = new Date(); // This will get the current time
                    const eventTime = new Date(data.time);
                    // @ts-ignore
                    const timeDifference = eventTime - currentTime;
                    const fourHoursInMilliseconds = 4 * 60 * 60 * 1000;
                    if (data?.event_type == "third_party") {
                      const newTab = window.open(data?.url, "_blank");
                    } else {
                      if (token == null && guestUser == null) {
                        setOpenLoginDialog(true);
                      } else {
                        if (timeDifference <= fourHoursInMilliseconds) {
                          setErrorText(true);
                          setTimeout(() => {
                            setErrorText(false);
                          }, 5000);
                        } else {
                          navigate(`/ticket-booking/${data.id}`);
                        }
                        // navigate(`/ticket-booking/${data.id}`);
                      }
                    }
                  }}
                  btnClass={
                    previewBanner
                      ? "book_now_button book_now_button_preview"
                      : "book_now_button"
                  }
                >
                  Purchase Tickets
                </Button>
              ) : (
                <Button size="s" variant="outlined" color="info" btnClass={'book_now_button'}>
                  Free Event
                </Button>
              )}
            </>
          )}
        {hideViewMore ? (
          <></>
        ) : (
          <Link
            className="event_card_link_website"
            to={`/upcoming-event/${data.id}`}
          >
            View More
            <img src={ArrowImg} />
          </Link>
        )}
      </Box>
      <Box
        className={`event_card_artists_website event_card_artists_website${data.artists?.length}`}
      >
        <Grid container className="event_card_artist_grid">
          {data.artists?.map((artist: any, i) => {
            return (
              <Grid
                key={i}
                className={
                  previewBanner
                    ? "event_card_artist_box_website event_card_images_website event_card_artist_box_website_preview"
                    : "event_card_artist_box_website event_card_images_website"
                }
                item
                md={12 / data.artists?.length}
                sm={data.artists?.length > 3 ? 6 : 12 / data.artists?.length}
                xs={data.artists?.length > 3 ? 6 : 12 / data.artists?.length}
              >
                <WebsiteArtistImage
                  image={artist.artist_image}
                  name={artist.name}
                  previewBanner={true}
                  arrLength={data.artists.length}
                  // marginLeft={3}
                />
                {/* {artist.link ||
                  (location.pathname.includes("upcoming-event") && (
                    <Box className="artist_bio_website">
                      <span>View Bio</span>
                      <img src={ArrowImg} />
                    </Box>
                  ))} */}
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {/* login dialog box */}
      <WebLoginDialog
        open={openLoginDialog}
        handleClose={handleCloseLoginDialog}
        handleOpenDialog={handleOpenRegisterDialog}
        handleOpenOtpDialog={handleOpenOtpDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        handleOpenForgotPasswordDialog={handleOpenForgotPasswordDialog}
        eventId={data?.id}
      />

      {/* register dialog box */}
      <WebRegisterDialog
        open={openRegisterDialog}
        handleClose={handleCloseRegisterDialog}
        handleOpenDialog={handleOpenLoginDialog}
        handleOtpOpen={() => {
          setOpenRegisterDialog(false);
          setOpenOtpDialog(true);
        }}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        page=""
      />

      {/* otp dialog box */}
      <WebOtpDialog
        open={openOtpDialog}
        handleClose={handleCloseOtpDialog}
        handleAlertBox={handleAlertBox}
        handlePasswordOpen={handleOpenPasswordDialog}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebPasswordDialog
        open={openPasswordDialog}
        handleClose={handleClosePasswordDialog}
        handleOpenDialog={handleOpenPasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebForgotPassword
        open={false}
        handleClose={handleCloseForgotPasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebChangePassword
        open={false}
        handleClose={handleCloseChangePasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        handleOpenLoginDialog={handleOpenLoginDialog}
        resetToken={resetToken}
      />
    </Box>
  );
};

export default EventCardWebsite;
