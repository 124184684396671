import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Checkbox,
  Divider,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./style.css";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import ReviewImage from "./images/review_card.png";
import ReviewImage2 from "./images/review_card2.png";
import useHttp from "../../../utils/hooks/useHttp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../atoms/loader";
import EventCardWebsite from "../../organism/eventCardWebsite";
import moment from "moment";
import { Input } from "../../atoms/inputs";
import PaymentPopup from "../../organism/paymentComponent";
import { ReqOptions } from "../../../utils/types/reqOptions";
import AlertCmp from "../../atoms/alert";
import PaymentCompletion from "../../organism/PaymentCompletionModal";
import deleteIcon from "./images/delete.svg";

const AttendeeDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [nameOne, setNameOne] = useState("");
  const [emailOne, setEmailOne] = useState("");
  const [nameTwo, setNameTwo] = useState("");
  const [emailTwo, setEmailTwo] = useState("");
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [eventData, setEventData] = useState<any>(null);
  const [ticketCount, setTicketCount] = useState(state?.userTypeArray?.length);
  const { id } = useParams();
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);
  const [openPayment, setOpenPayment] = useState<any>(false);
  const [amount, setAmount] = useState<any>(0);
  const [membership, setMembership] = useState<any>(null);
  const [webUser, setWebUser] = useState<any>(null);
  const [advanceDiscount, setAdvanceDiscount] = useState<any>(0);
  const [attendees, setAttendees] = useState<any>([]);
  const [event, setEvent] = useState<any>("");
  const [maxFreePasses, setMaxFreePasses] = useState<any>(
    webUser?.no_of_free_passes
  );

  const createAttendee = () => {
    const arr = [];
    for (let i = 0; i < state?.userTypeArray?.length; i++) {
      arr.push(state?.userTypeArray[i]);
    }
    setAttendees(arr);
  };

  const handleAttendeeChange = (index: any, key: any, value: any) => {
    const updatedAttendees = [...attendees]; // Create a copy of the current attendees array
    updatedAttendees[index][key] = value; // Set the specified value for the given key
    // console.log(updatedAttendees); shuba
    setAttendees(updatedAttendees); // Update the state with the modified array
  };

  const handleDeleteAttendee = (index: any) => {
    // Create a copy of the current attendees array
    const updatedAttendees = [...attendees];

    // Remove the item at the specified index
    updatedAttendees.splice(index, 1);

    // Update the state with the modified array
    setAttendees(updatedAttendees);
    setTicketCount(ticketCount - 1);
  };
  useEffect(() => {
    createAttendee();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("web_user");
    if (user) {
      const web_user = JSON.parse(user);
      setWebUser(web_user);
      setMaxFreePasses(web_user?.no_of_free_passes);
      const reqOptions = {
        url: `/memberships/${web_user?.membership_id}`,
        method: "GET",
      };

      getMembership(reqOptions);

      // console.log(getMembership(reqOptions))
    }
  }, []);

  console.log("membership>>>>>>", membership);

  const transformMembershipData = (data: any) => {
    setMembership(data?.Membership);
  };
  // console.log("MMMMMMM", membership);

  const [resultArray, setResultArray] = useState<any>([]);

  function countNames() {
    const nameCount: any = {};

    // Initialize the counts for all possible names (ticket types)
    event?.ticket_details?.forEach((item: any) => {
      const name = item.title;
      nameCount[name] = 0;
    });

    // Count the occurrences of each name (attendees)
    attendees?.forEach((item: any) => {
      const name = item.user_type;
      if (name) {
        nameCount[name] = (nameCount[name] || 0) + 1;
      }
    });

    // Create the result array
    const resultArray = Object.entries(nameCount).map(([name, count]: any) => {
      const ticketInfo = event?.ticket_details?.find(
        (item: any) => item.title === name
      );
      if (ticketInfo) {
        const price = count * parseInt(ticketInfo.advance_value, 10);
        return { [`${name} X ${count}`]: price.toString() };
      } else {
        return { [`${name} X ${count}`]: "0" };
      }
    });

    setResultArray(resultArray);
    return;
  }
  useEffect(() => {
    countNames();
  }, [attendees, eventData, membership]);

  const {
    loading: load,
    error: err,
    sendRequestWithoutToken: getMembership,
  } = useHttp(transformMembershipData);

  async function transformData(data: any) {
    // console.log("12345657weqrty",data);

    if (await data.status) {
      const today = new Date();
      setEvent(data?.event);

      const obj = {
        id: data.event.id,
        title: data.event.title,
        date: data?.event?.date,
        location: `${data.event.venue}`,
        locationLink: data?.event?.location,
        time: `${moment(data.event.date).format("MMMM D YYYY")}, 
        ${data.event.start_date}`,
        artists: data.event.artists,
        status: data.event.start_date > today ? "upcoming" : "",
        ticket_details: data.event.ticket_details,
        ticket_details_member: data.event.ticket_details.find((item: any) =>
          item.title === "Price for Members, Students, Senior Citizens *"
            ? data.event.ticket_details
            : data.event.ticket_details[1]
        ),
        ticket_details_not_member: data.event.ticket_details.find((item: any) =>
          item.title === "Price for Non Members *"
            ? data.event.ticket_details
            : data.event.ticket_details[0]
        ),
      };

      setEventData(obj);
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showRightDiv, setShowRightDiv] = useState(false);
  const [paymentCompletion, setPaymentCompletion] = useState<boolean>(false);
  const user: any = localStorage.getItem("user");
  const handleNextButtonClick = () => {
    setShowRightDiv(true);
  };

  useEffect(() => {
    const reqOptions = {
      url: `/get_event`,
      method: "GET",
      params: {
        id,
      },
    };

    sendRequestWithoutToken(reqOptions);
  }, []);

  const handleDecrement = () => {
    if (ticketCount > 1) {
      setTicketCount(ticketCount - 1);
      setAttendees(attendees.slice(0, -1));
    }
  };

  const handleIncrement = () => {
    if (ticketCount > 0) {
      setTicketCount(ticketCount + 1);
      setAttendees([...attendees, { name: "", email: "", user_type: "" }]);
    }
  };

  // useEffect(() => {
  //   if (eventData && ticketCount) {
  //     if (membership != null) {
  //       if (ticketCount <= webUser?.no_of_free_passes) {
  //         setAmount(0)
  //         setAdvanceDiscount(0)
  //       } else {
  //         // setAmount((eventData?.ticket_details_member.advance_value * ticketCount)-membership?.save_upto * ticketCount)
  //         let amt = (ticketCount - webUser?.no_of_free_passes) * (eventData?.ticket_details[0].advance_value - membership?.save_upto)
  //         amt > 0 ? setAmount(amt) : setAmount(0)
  //         setAdvanceDiscount((eventData?.ticket_details[0]?.door_value - eventData?.ticket_details[0]?.advance_value) * ticketCount)
  //       }
  //     } else {
  //       setAmount(eventData?.ticket_details[1]?.advance_value * ticketCount)
  //       setAdvanceDiscount((eventData?.ticket_details[1]?.door_value - eventData?.ticket_details[1]?.advance_value) * ticketCount)
  //     }
  //   }
  // }, [eventData, ticketCount, membership])

  const [membershipDiscount, setMembershipDiscount] = useState(0);

  // console.log("qwertyu,", eventData);
  // console.log("attendees: ", attendees);

  useEffect(() => {
    if (eventData && ticketCount) {
      if (event?.sub_type !== "free") {
        if (membership !== null) {
          // console.log("membership", membership);

          let totalAmount = 0;
          let totalAdvanceDiscount = 0; // Initialize the total advance discount
          let freePassesCount = webUser?.no_of_free_passes;
          // let freePassesCount = 0;
          let membershipDiscount = 0; // Initialize the membership discount
          // let totalAmountValue = 0; // Initialize the total amount value for the all tickets amount

          // console.log("attendee", attendees);

          attendees.forEach((attendee: any) => {
            const user_type = attendee.user_type;
            // console.log("eventdata ticket title:"+eventData);
            // console.log(eventData.ticket_details);

            const ticketDetail = eventData?.ticket_details.find(
              (detail: any) => detail.title === user_type
            );
            // console.log("ticketDetail: ", ticketDetail);

            if (ticketDetail) {
              // console.log('ticketDetail', ticketDetail);

              if (ticketDetail.title == "Price for Non Members *") { // to check for non members ticket and add price withouut discounts to total amount
                totalAdvanceDiscount +=
                  parseInt(ticketDetail.door_value) -
                  parseInt(ticketDetail.advance_value);
                totalAmount += parseInt(ticketDetail.advance_value);
              } 
              // else if (freePassesCount > 0) {
              //   // console.log("freePass");
              //   // console.log(attendee.use_pass);

              //   // if(attendee.use_pass){

              //   // console.log("attendees.use_pass");

              //   totalAmount += 0; // First 'no_of_free_passes' tickets are free
              //   freePassesCount--; // Reduce the count of free passes used

              //   // console.log("2222", ticketDetail);

              //   // membershipDiscount += parseInt(membership?.save_upto || 0, 10);
              //   // console.log("membership222", membership);

              //   // console.log("membershipDiscount", membershipDiscount);

              //   // }
              //   totalAdvanceDiscount +=
              //     parseInt(ticketDetail.door_value) -
              //     parseInt(ticketDetail.advance_value);

              //   // totalAmountValue += parseInt(ticketDetail.advance_value, 10)
              // } 
              else {
                // const ticketCost = parseInt(ticketDetail.advance_value, 10) - parseInt(membership?.save_upto || 0, 10);
                const ticketCost = parseInt(ticketDetail.advance_value);
                if (!isNaN(ticketCost) && ticketCost >= 0) {
                  totalAmount += ticketCost;
                  // Calculate the advance discount and add it to the totalAdvanceDiscount
                  totalAdvanceDiscount +=
                    parseInt(ticketDetail.door_value) -
                    parseInt(ticketDetail.advance_value);
                  // Add the membership discount to the total membershipDiscount
                  // console.log("membership in the log",membership);

                  // membershipDiscount += parseInt(membership?.save_upto || 0, 10);
                } else {
                  totalAmount += 0;
                  // membershipDiscount += parseInt(membership?.save_upto || 0, 10);
                }
              }
            }
          });

          // totalAmount += totalAmountValue - membershipDiscount;

          // if(totalAmount < 0) totalAmount = 0;

          setAmount(totalAmount);
          setMembershipDiscount(membershipDiscount); // Store the membership discount
          setAdvanceDiscount(totalAdvanceDiscount); // Store the advance discount
        } else {
          let totalAmount = 0;
          let totalAdvanceDiscount = 0;
          attendees.forEach((attendee: any) => {
            const user_type = attendee.user_type;
            const ticketDetail = eventData?.ticket_details.find(
              (detail: any) => detail.title === user_type
            );

            if (ticketDetail) {
              totalAmount += parseInt(ticketDetail.advance_value);
              totalAdvanceDiscount +=
                parseInt(ticketDetail.door_value) -
                parseInt(ticketDetail.advance_value);
            }
          });

          setAmount(totalAmount);
          setMembershipDiscount(0); // No membership discount when membership is not applicable
          setAdvanceDiscount(totalAdvanceDiscount); // Store the advance discount
        }
      }
    }
  }, [attendees, membership, eventData]);

  // console.log(membership);

  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

  const formatDateTime = (timestamp: any) => {
    const dateObj = new Date(timestamp);

    // Format time as "15:30:00"
    const hours = String(dateObj.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    // Format date as "13/07/2023"
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dateObj.getUTCFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    return { time: formattedTime, date: formattedDate };
  };

  const completeTicket = () => {
    setPaymentLoading(true);
    const loggedInUser = localStorage.getItem("web_user");
    const user = JSON.parse(loggedInUser || "{}");
    const guest = localStorage.getItem("guest_user");
    const today = new Date();
    const { time, date } = formatDateTime(today);
    const requestOptions: ReqOptions = {
      url: `/payments/payment_details`,
      method: "POST",
      data: {
        email: user?.email,
        name: user?.username,
        payment_time: time,
        payment_date: date,
        type_of_purchase_ticket: user?.membership_name,
        user_type:
          guest == "true" || loggedInUser == null ? "guest" : "registered_user",
        transaction_type: "ticket",
        total_amount: amount,
        event_managment_id: eventData?.id,
        no_of_purchase_tickets: ticketCount,
        membership_id: user?.membership_id,
        // "attendee_details": attendeeDetails
        attendee_details: attendees,
      },
    };

    sendTicketRequest(requestOptions);
  };

  const transformTicketData = (data: any) => {
    const user = {
      ...webUser,
      no_of_free_passes:
        webUser?.no_of_free_passes - ticketCount >= 0
          ? webUser?.no_of_free_passes - ticketCount
          : 0,
    };
    localStorage.setItem("web_user", JSON.stringify(user));
    setPaymentLoading(false);
    setPaymentCompletion(true);
    setTimeout(() => {
      setPaymentCompletion(false);
    }, 2000);

    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  const {
    loading: loadin,
    error: er,
    sendRequestWithoutToken: sendTicketRequest,
  } = useHttp(transformTicketData);

  // const [paid, setPaid] = useState(false);
  // const [payerror, setError] = useState(null);
  // const paypalRef = useRef();

  // useEffect(() => {
  //   if(eventData && ticketCount){
  //   // @ts-ignore
  //   window.paypal
  //     .Buttons({
  //       createOrder: (data: any, actions: any) => {
  //         return actions.order.create({
  //           intent: "CAPTURE",
  //           purchase_units: [
  //             {
  //               description: "Your description",
  //               amount: {
  //                 currency_code: "USD",
  //                 value: (eventData?.ticket_details_member.advance_value *ticketCount)+'',
  //               },
  //             },
  //           ],
  //         });
  //       },
  //       onApprove: async (data: any, actions: any) => {
  //         const order = await actions.order.capture();
  //         setPaid(true);
  //         console.log("order", order);
  //       },
  //       onError: (err: any) => {
  //         console.error("hello err ", err);
  //       },
  //     })
  //     .render(paypalRef.current);
  //   }
  // }, [eventData, ticketCount]);
  const [attendeeDetails, setAtteneeDetails] = useState<any>([]);
  const [emailOneError, setEmailOneError] = useState(false);
  const [emailTwoError, setEmailTwoError] = useState(false);

  // const getDetailsArray = () => {
  //   const detailsArray = [];
  //   let err = true;

  //   const validateEmail = (email: any) => {
  //     // Regular expression to validate email format
  //     const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,3}$/;
  //     return emailRegex.test(email.toLowerCase());
  //   };

  //   if (nameOne) {
  //     if (emailOne) {
  //       if (validateEmail(emailOne)) {
  //         detailsArray.push({ name: nameOne, email: emailOne });
  //         setEmailOneError(false); // Clear the error when valid data is available
  //       } else {
  //         setEmailOneError(true);
  //         setShowSuccessAlert({
  //           show: true,
  //           message: "Invalid email",
  //         });
  //         setTimeout(() => {
  //           setShowSuccessAlert({
  //             show: false,
  //             message: "",
  //           });
  //         }, 2500);
  //         err = false;
  //       }
  //     } else {
  //       setEmailOneError(true); // Show an error if nameOne is filled but emailOne is empty
  //       setShowSuccessAlert({
  //         show: true,
  //         message: "Enter person one email",
  //       });
  //       setTimeout(() => {
  //         setShowSuccessAlert({
  //           show: false,
  //           message: "",
  //         });
  //       }, 2500);
  //       err = false;
  //     }
  //   } else {
  //     setEmailOneError(false); // Clear the error when nameOne is not filled
  //   }

  //   if (nameTwo) {
  //     if (emailTwo) {
  //       if (validateEmail(emailTwo)) {
  //         detailsArray.push({ name: nameTwo, email: emailTwo });
  //         setEmailTwoError(false); // Clear the error when valid data is available
  //       } else {
  //         setEmailTwoError(true);
  //         setShowSuccessAlert({
  //           show: true,
  //           message: "Invalid email",
  //         });
  //         setTimeout(() => {
  //           setShowSuccessAlert({
  //             show: false,
  //             message: "",
  //           });
  //         }, 2500);
  //         err = false;
  //       }
  //     } else {
  //       setEmailTwoError(true); // Show an error if nameTwo is filled but emailTwo is empty
  //       setShowSuccessAlert({
  //         show: true,
  //         message: "Enter person two email",
  //       });
  //       setTimeout(() => {
  //         setShowSuccessAlert({
  //           show: false,
  //           message: "",
  //         });
  //       }, 2500);
  //       err = false;
  //     }
  //   } else {
  //     setEmailTwoError(false); // Clear the error when nameTwo is not filled
  //   }

  //   if (nameTwo && !nameOne) {
  //     setEmailOneError(true); // Show an error if nameTwo is filled but nameOne is empty
  //     setShowSuccessAlert({
  //       show: true,
  //       message: "Enter name and email for Person 1",
  //     });
  //     setTimeout(() => {
  //       setShowSuccessAlert({
  //         show: false,
  //         message: "",
  //       });
  //     }, 2500);
  //     err = false;
  //   }

  //   setAtteneeDetails(detailsArray);
  //   return err;
  // };

  const getDetailsArray = () => {
    let isValid = true;
    const validatedAttendees: any = [];

    attendees.forEach((attendee: any, index: number) => {
      const { name, user_type, email } = attendee;
      const errors = { name: false, user_type: false, email: false };

      if (!webUser) {
        if (!name) {
          errors.name = true;
          isValid = false;
        }
      }

      if (!user_type) {
        errors.user_type = true;
        isValid = false;
      }

      if (email) {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,3}$/;
        if (!emailRegex.test(email.toLowerCase())) {
          errors.email = true;
          isValid = false;
        }
      }

      if (!isValid) {
        // Show a success alert for validation errors
        setShowSuccessAlert({
          show: true,
          message: "Please fill the attendee details.",
        });
        setTimeout(() => {
          setShowSuccessAlert({
            show: false,
            message: "",
          });
        }, 2500);
      }

      validatedAttendees.push({ name, user_type, email, errors });
    });

    // return { isValid, validatedAttendees };
    setAtteneeDetails(validatedAttendees);
    return isValid;
  };

  const [showSuccessAlert, setShowSuccessAlert] = useState({
    show: false,
    message: "",
  });
  const webUserString: any = localStorage.getItem("web_user");
  const webUserprofile: any = webUserString ? JSON.parse(webUserString) : null;
  const handlePaymentSuccessClose = () => {
    setPaymentCompletion(false);
  };
  const [passesUsed, setPassesUsed] = useState(0);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    attendees.map(() => false)
  );

  // console.log(23333,resultArray)
  return (
    <>
      {showSuccessAlert.show && (
        <AlertCmp type="error" position="user_side">
          {showSuccessAlert.message}
        </AlertCmp>
      )}
      {loading && <Loader />}
      <Box className="attendeedetails_top_website">
        <Box className="attendeedetails_wrapper">
          {/* {isMobile && ( */}
          <Button
            className="back_button"
            variant="outlined"
            onClick={() => window.history.back()}
          >
            <ArrowBackIosNewIcon />
          </Button>
          {/* )} */}
          <Typography variant="h4" component="h4">
            Attendee Details
          </Typography>
        </Box>
        <Grid
          container
          columnSpacing={!isMobile ? 4 : 25}
          style={{ padding: "0px 12px 0px 0px" }}
        >
          {/* <div style={{overflowY: 'scroll', flex: 1, height: '500px'}}> */}
          {(!isMobile || (isMobile && !showRightDiv)) && (
            <Grid
              item
              xl={6}
              md={6}
              sm={6}
              xs={12}
              style={{ paddingLeft: !isMobile ? "15px" : "100px" }}
            >
              {attendees.map((item: any, idx: any) => {
                // Track the number of attendees who have selected "Use Membership pass"
                // const selectedFreePasses = attendees.slice(0, idx).filter((att: any) => att.use_pass).length;
                const isCheckboxAvailable =
                  passesUsed < webUser?.no_of_free_passes ||
                  selectedCheckboxes[idx];
                // Determine whether the "Use Membership pass" checkbox should be available for this attendee
                // const isCheckboxAvailable = selectedFreePasses < maxFreePasses;
                return (
                  <>
                    <Box className="attendeedetail_inputs">
                      <Box style={{ display: "flex" }}>
                        <Box sx={{ marginTop: "15px" }}>
                          <Typography
                            variant="h5"
                            component="h5"
                            className="attendeedetail_inputs_heading"
                          >
                            {`Attendee ${idx + 1}`}
                          </Typography>
                          <InputsWithLabel
                            label="Name*"
                            type="text"
                            name="name"
                            id="name"
                            labelColor="#FDFCFC"
                            placeholder="Enter name here"
                            value={item?.name}
                            handleInputChange={(e: any) => {
                              handleAttendeeChange(idx, "name", e.target.value);
                            }}
                          />
                        </Box>
                        <FormControl
                          sx={{
                            flex: 1,
                            marginTop: "50px",
                            marginLeft: "30px",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                            }}
                          >
                            <Typography
                              variant="h5"
                              component="h5"
                              className="attendeedetail_inputs_heading"
                              style={{ fontWeight: "bold" }}
                            >
                              Ticket Type*
                            </Typography>
                            <img
                              onClick={() => {
                                handleDeleteAttendee(idx);
                              }}
                              style={{ cursor: "pointer" }}
                              src={deleteIcon}
                            />
                          </div>
                          {item?.user_type == "" && (
                            <InputLabel
                              id={`select-label-${idx}`}
                              style={{
                                color: "#a2a2a2",
                                marginTop: "30px",
                                fontSize: "15px",
                              }}
                              shrink={false}
                            >
                              Select a role
                            </InputLabel>
                          )}
                          <Select
                            style={{
                              backgroundColor: "#fff",
                              height: "47px",
                              marginTop: "12px",
                            }}
                            labelId={`select-label-${idx}`}
                            id={`select-${idx}`}
                            value={item?.user_type}
                            onChange={(e: any) => {
                              handleAttendeeChange(
                                idx,
                                "user_type",
                                e.target.value
                              );
                            }}
                          >
                            {
                              event
                                ? event.ticket_details.map((item: any) => {
                                    // Check if item.title is not "members"
                                    if (
                                      (item.title === "Members" ||
                                        item.title === "Member") &&
                                      membership == null
                                    ) {
                                      return null;
                                    }
                                    return (
                                      <MenuItem
                                        key={item.title}
                                        value={item.title}
                                      >
                                        {`${item.title} ($${item.advance_value})`}
                                      </MenuItem>
                                    ); // If the item.title is "members", don't render it
                                  })
                                : null // Render nothing if event is null
                            }
                          </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ marginTop: "15px" }}>
                        <InputsWithLabel
                          label="Email (optional)"
                          type="email"
                          name="email"
                          id="email"
                          labelColor="#FDFCFC"
                          placeholder="Enter Email here"
                          value={item?.email}
                          handleInputChange={(e: any) => {
                            handleAttendeeChange(idx, "email", e.target.value);
                          }}
                          // helperText="Invalid email address"
                          // msg={emailOneError}
                        />
                      </Box>
                      {isCheckboxAvailable &&
                        webUser?.no_of_free_passes &&
                        webUser?.no_of_free_passes > 0 && (
                          <Box
                            sx={{ marginTop: "15px" }}
                            className="checkbox_wrapper"
                          >
                            <Checkbox
                              sx={{
                                color: "#FFFFFF",
                                "&.Mui-checked": {
                                  color: "#e4bb35 !important",
                                },
                              }}
                              checked={selectedCheckboxes[idx]}
                              onChange={(e) => {
                                const updatedSelectedCheckboxes = [
                                  ...selectedCheckboxes,
                                ];
                                updatedSelectedCheckboxes[idx] =
                                  e.target.checked;
                                setSelectedCheckboxes(
                                  updatedSelectedCheckboxes
                                );
                                const updatedPassesUsed = e.target.checked
                                  ? passesUsed + 1
                                  : passesUsed - 1;
                                setPassesUsed(updatedPassesUsed);
                                handleAttendeeChange(
                                  idx,
                                  "use_pass",
                                  e.target.checked
                                );
                              }}
                            />
                            <Typography variant="h5" component="h5">
                              Use Membership pass
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  </>
                );
              })}
              <Button
                className="pay_button"
                variant="outlined"
                color="info"
                size="large"
                style={{ width: "25%", marginLeft: "112px" }}
                onClick={() => handleIncrement()}
              >
                {`Add +1`}
              </Button>

              {/* <Box className="attendeedetail_inputs">
                <Box sx={{ marginTop: "15px" }}>
                  <Typography
                    variant="h5"
                    component="h5"
                    className="attendeedetail_inputs_heading"
                  >
                    Attendee 2
                  </Typography>
                  <InputsWithLabel
                    label="Name (optional)"
                    type="text"
                    name="name"
                    id="name"
                    labelColor="#FDFCFC"
                    placeholder="Enter name here"
                    value={nameTwo}
                    handleInputChange={(e: any) => setNameTwo(e.target.value)}
                  />
                </Box>
                <Box sx={{ marginTop: "15px" }}>
                  <InputsWithLabel
                    label="Email (optional)"
                    type="email"
                    name="email"
                    id="email"
                    labelColor="#FDFCFC"
                    placeholder="Enter Email here"
                    value={emailTwo}
                    handleInputChange={(e: any) => setEmailTwo(e.target.value)}
                    // helperText="Invalid email address"
                    // msg={emailTwoError}
                  />
                </Box>
                {
                  webUserprofile?.no_of_free_passes && webUserprofile?.no_of_free_passes>0 && (
                    <Box sx={{ marginTop: "15px" }} className="checkbox_wrapper">
                    <Checkbox
                      sx={{
                        color: "#FFFFFF",
                        "&.Mui-checked": {
                          color: "#e4bb35 !important",
                        },
                      }}
                      checked={checkedTwo}
                      onChange={(e) => setCheckedTwo(e.target.checked)}
                    />
                    <Typography variant="h5" component="h5">
                      Use Membership pass
                    </Typography>
                  </Box>
                  )
                }
               
              </Box> */}

              {isMobile && !showRightDiv && (
                <Button
                  className="next_button"
                  variant="outlined"
                  color="info"
                  size="large"
                  disabled={
                    nameOne &&
                    emailOne &&
                    nameTwo &&
                    emailTwo &&
                    ticketCount > 0
                      ? true
                      : false
                  }
                  onClick={handleNextButtonClick}
                >
                  Next
                </Button>
              )}
            </Grid>
          )}
          {/* </div> */}

          {(!isMobile || (isMobile && showRightDiv)) && (
            <Grid item xl={6} md={6} sm={6} xs={12}>
              {isMobile && (
                <Box
                  className="attendeedetails_wrapper"
                  style={{ left: `${isMobile && "-95px"}` }}
                >
                  <Button
                    className="back_button"
                    variant="outlined"
                    onClick={() => {
                      isMobile ? setShowRightDiv(false) : window.history.back();
                    }}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                </Box>
              )}
              <Card variant="outlined" className="review_card">
                <CardHeader
                  title="Review your ordersss"
                  className="card_title"
                />
                <Box
                  sx={{
                    width: "100%",
                    height: "350px",
                  }}
                >
                  {eventData && (
                    <EventCardWebsite
                      data={eventData}
                      previewBanner={true}
                      hideViewMore={true}
                    />
                  )}
                </Box>
                <CardContent className="card_content">
                  <Box>
                    <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_title"
                    >
                      No of tickets
                    </Typography>
                    {/* <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_title"
                    >
                      Price per ticket
                    </Typography> */}
                    {resultArray.map((item: any, index: any) => (
                      <Typography
                        variant="h5"
                        component="h5"
                        className="card_content_item_title"
                      >
                        {Object.values(item)[0] != 0 && Object.keys(item)[0]}
                      </Typography>
                    ))}
                    {/* <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_title"
                    >
                      Membership discount
                    </Typography> */}
                    <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_title"
                    >
                      Amount you saved by booking in advance
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_total"
                    >
                      Total
                    </Typography>
                  </Box>
                  <Box className="card_content_right">
                    <Box className="no_of_tickets">
                      {/* <IconButton size="small" onClick={handleDecrement}>
                        <RemoveIcon
                          style={{
                            color: "#D5D7DB",
                            fontSize: "14px",
                            fontWeight: "200",
                            marginBottom: "15px",
                          }}
                        />
                      </IconButton> */}
                      <Typography
                        variant="body1"
                        component="span"
                        style={{
                          color: "#D5D7DB",
                          fontSize: "14px",
                          fontWeight: "200",
                          // marginBottom: "15px",
                        }}
                      >
                        {ticketCount}
                      </Typography>
                      {/* <IconButton size="small" onClick={handleIncrement}>
                        <AddIcon
                          style={{
                            color: "#D5D7DB",
                            fontSize: "14px",
                            fontWeight: "200",
                            marginBottom: "15px",
                          }}
                        />
                      </IconButton> */}
                    </Box>
                    {/* <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_title"
                    >
                      {membership ?
                        `$${eventData?.ticket_details[0].advance_value}`
                        :
                        `$${eventData?.ticket_details[1]?.advance_value}`
                      }
                    </Typography> */}
                    {resultArray?.length > 0 &&
                      resultArray.map((item: any, index: any) => (
                        <Typography
                          variant="h5"
                          component="h5"
                          className="card_content_item_title"
                        >
                          {/* @ts-ignore */}
                          {Object.values(item)[0] != 0 &&
                            `$${Object.values(item)[0]}`}
                        </Typography>
                      ))}
                    {/* <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_title"
                    >
                      {membership ? `$${membershipDiscount}` : '$0'}

                    </Typography> */}
                    <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_title"
                    >
                      ${advanceDiscount}
                      {/* {eventData?.ticket_details_not_member.advance_value -
                        eventData?.ticket_details_member.advance_value} */}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_total"
                    >
                      ${amount}
                    </Typography>
                  </Box>
                </CardContent>
                {/* <Divider className="divider" /> */}
                {/* <CardContent className="card_content">
                  <Box>
                    {webUser?.membership_type && <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_title"
                    >
                      {webUser?.membership_type} Member - {webUser?.no_of_free_passes} free Passes Left
                    </Typography>}
                  </Box>
                  <Box className="card_content_right">
                    <Typography
                      variant="h5"
                      component="h5"
                      className="card_content_item_title"
                    >
                      <InfoOutlinedIcon className="info_icon" />
                    </Typography>
                  </Box>
                </CardContent> */}
                <CardActions>
                  {/* <Box ref={paypalRef}></Box > */}
                  <Button
                    className="pay_button"
                    variant="outlined"
                    color="info"
                    size="large"
                    disabled={
                      nameOne &&
                      emailOne &&
                      nameTwo &&
                      emailTwo &&
                      ticketCount < 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      let temp = getDetailsArray();
                      if (amount > 0) {
                        if (temp) {
                          // if (webUser?.no_of_free_passes >= ticketCount) {
                          //   completeTicket();
                          // } else {
                          //   setOpenPayment(true);
                          // }

                          // open payment modal without checking for the free passes 
                          setOpenPayment(true);
                        }
                      } else {
                        if (temp) {
                          completeTicket();
                        }
                      }
                    }}
                  >
                    {amount > 0 ? `Pay via PayPal` : `Buy Tickets`}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
      {amount != null && openPayment && (
        <PaymentPopup
          open={openPayment}
          handleClose={() => {
            setOpenPayment(false);
          }}
          amount={amount}
          type={"ticket"}
          eventData={eventData}
          ticket={ticketCount}
          attendeeDetails={attendeeDetails}
        />
      )}
      <PaymentCompletion
        open={paymentCompletion}
        handleClose={handlePaymentSuccessClose}
        ticket={ticketCount}
      />
    </>
  );
};

export default AttendeeDetails;
