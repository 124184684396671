import React, { useEffect, useState } from "react";
import SectionHeading from "../../molecules/sectionHeading";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PastEventSection from "../../organism/pastEventSection";
import TitleDivider from "../../atoms/titleDivider";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import moment from "moment";
import Loader from "../../atoms/loader";

const pastYears = ["2023", "2022", "2021", "2020"];

const PastEvents = ({
  pageCheck,
  update_date,
}: {
  pageCheck?: boolean;
  update_date?: any;
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
    };
  }, []);

  const [pastEvents, setPastEvents] = useState<any>([]);
  const [checkPages, setCheckPages] = useState<boolean>(true);

  const [newArrFilter, setNewArrFilter] = useState([]);
  const [filterArr, setFilterArr] = useState<any[]>([]);

  useEffect(() => {
    setCheckPages(pageCheck || true);
  }, [pageCheck]);

  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  const filterYear = (year: any,i:any) => {
   
    // setFilterArr(result) 
  };

  useEffect(()=>{
    const res=pastYears.map((year)=>{
   // @ts-ignore
   return newArrFilter.filter((obj) => obj.date.split(" ")[2].replace(/,/g, '') == year);
  })
  setFilterArr(res)

  },[newArrFilter]) 

    // loading screen 
    const  [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    },[])

  return (
    <Box>
      {loading && <Loader />}
      <Box className="past_event_top_website">
        <SectionHeading title="All Past Events" />
        <Box className="past_event_selectbox_website">
          <p>Select Year</p>
          {/* filter() */}
          <Box className="past_year_dropdown_website">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={age}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={""}>Select Year</MenuItem>
                <MenuItem value={"2023 - 2022"}>2023 - 2022</MenuItem>
                <MenuItem value={"2022 - 2021"}>2022 - 2021</MenuItem>
                <MenuItem value={"2021 - 2020"}>2021 - 2020</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      {/* {pastYears.map((year, i) => {
        // filterYear(year,i)
        return(
        <Box key={i}>
          <Box>
            <TitleDivider>
              <h4 className="year_title_website">{year}</h4>
            </TitleDivider>
          </Box>

          <PastEventSection
            hideTitle={true}
            hideViewMore={true}
            pageCheck={true}
            setNewArrFilter={setNewArrFilter}
            filterArray={filterArr[i]}
          />
        </Box>
        )
      })} */}
      {pastYears.map((year, i) => (
        age !== "" ?
          age.split('-').map((inherited) => (
           inherited.trim() == year?
           
          //  filterArr[i] && filterArr[i].length > 0 &&
              <Box key={i}>
                <Box>
                  <TitleDivider>
                    <h4 className="year_title_website">{year}</h4>
                  </TitleDivider>
                </Box>
                <PastEventSection hideTitle={true} hideViewMore={true} pageCheck={true} setNewArrFilter={setNewArrFilter} filterArray={filterArr[i]} />
              </Box>
              : ''
          )) :
          // filterArr[i] && filterArr[i].length > 0 &&
           <Box key={i}>
            <Box>
              <TitleDivider>
                <h4 className="year_title_website">{year}</h4>
              </TitleDivider>
            </Box>
            <PastEventSection hideTitle={true} hideViewMore={true} pageCheck={true}  setNewArrFilter={setNewArrFilter} filterArray={filterArr[i]} />
          </Box>
      ))}
    </Box>
  );
};

export default PastEvents;
